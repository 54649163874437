import { AudioVisualArticle, ExtraPageData, IndexPageTagData, TextualArticle, isContextWithElements } from '@hubcms/domain-cook';
import { Author, HeroDate, SocialSharingItem, StoryHeaderData, StorySource } from '@hubcms/domain-story';
import { StoryElement, HeroMediaStoryElement } from '@hubcms/domain-story-elements';
import { RepublishedFieldData } from './republished-field-data';
import { SponsoredBannerData } from './banner-data/sponsored-banner-data';

type TagIntroData = Exclude<IndexPageTagData['intro'], undefined>[number];
export type ArticleDataWithElements = TextualArticle['context'] | ExtraPageData['context'] | TagIntroData;
// TODO: CW-63 - This should be removed when all is done
export type ArticleCookData = ArticleDataWithElements | AudioVisualArticle['context'];

export function isTagIntroData(articleData: ArticleCookData): articleData is TagIntroData {
  return !!articleData && (articleData.__typename === 'TagIntro' || articleData.__typename === 'TagIntroB2B');
}

export function isArticleDataWithElements(articleData: ArticleCookData | null): articleData is ArticleDataWithElements {
  if (articleData === null) {
    return false;
  }
  return isTagIntroData(articleData) || isContextWithElements(articleData);
}

export type ArticleData = {
  articleId: string;
  authors: Author[];
  bodyElements: StoryElement[];
  bannerData?: SponsoredBannerData;
  headerData: StoryHeaderData;
  heroMedia?: HeroMediaStoryElement;
  isPremium: boolean;
  publishedDate?: HeroDate;
  republishedFieldData?: RepublishedFieldData;
  socials: SocialSharingItem[];
  sourceData?: StorySource;
  tags: Array<{
    name: string;
    uri: string;
  }>;
  updatedDate?: HeroDate;
  url: string;
};

export function createArticleData({
  articleId = '',
  authors = [],
  bodyElements = [],
  headerData = {},
  isPremium = false,
  socials = [],
  tags = [],
  url = '',
  ...optionalProps
}: Partial<ArticleData>): ArticleData {
  return {
    articleId,
    authors,
    socials,
    bodyElements,
    headerData,
    isPremium,
    tags,
    url,
    ...optionalProps,
  };
}
