import { Brand } from '@news-components/newsletter-subscription-widget';

type Brand = typeof Brand;

export const newsletterBrandMap = new Map<string, Brand>([
  ['az', Brand.AachenerZeitung],
  ['lw', Brand.Wort],
  ['lt', Brand.LuxembourgTimesOnline],
  ['vl', Brand.Virgule],
  ['co', Brand.Contacto],
]);
