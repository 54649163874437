import { ArticleDataWithElements } from '@hubcms/domain-article';
import { SectionParams } from '@hubcms/domain-cook';
import { isNonNull } from '@hubcms/utils-browser';
import { mapStoryElementData } from '@hubcms/utils-story-elements';
import { getStoryHeaderData } from '../utils/getStoryHeaderData';
import { findHeroMediaId, validateHeroMedia } from '../utils/getStoryHeroMedia';

export type GetStoryElementsOptions = {
  hasMedia: boolean;
  frontendTemplate?: string | null;
  sectionParams: SectionParams;
};

export function getStoryElements(
  articleData: ArticleDataWithElements,
  options: GetStoryElementsOptions = { sectionParams: {}, hasMedia: true },
) {
  const heroMediaId = options.hasMedia ? findHeroMediaId(articleData) : null;

  const storyline = articleData.storyline || [];

  const storyElements = storyline
    .map(elementId => {
      const element = articleData.elements.find(({ id }) => id === elementId);
      if (!element) {
        return null;
      }
      const isHeroMedia = elementId === heroMediaId;
      const storyElement = mapStoryElementData(element, {
        elements: articleData.elements,
        frontendTemplate: options.frontendTemplate,
        sectionParams: options.sectionParams,
        isExtended: isHeroMedia,
      });
      return storyElement;
    })
    .filter(isNonNull);

  const heroMedia = heroMediaId ? validateHeroMedia(storyElements.find(({ id }) => id === heroMediaId)) : null;
  const { headerData, headerDataElementIds } = getStoryHeaderData(articleData, storyElements);

  return {
    storyHeaderData: headerData,
    heroMedia,
    bodyElements: storyElements.filter(({ id }) => heroMedia?.id !== id && !headerDataElementIds.includes(id)),
  };
}
